import React from 'react'
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import propertyManagement from "../../../../assets/icons/propertyManagement.svg"
import loadingRound from "../../../../assets/icons/loadingRound.svg"
import capitalImprovements from "../../../../assets/icons/capitalImprovement.svg"
import financeRealEstate from "../../../../assets/icons/FinanceRealEstate.svg";
import financialReporting from "../../../../assets/icons/FinancialReporting.svg";
import recoverAssetValue from "../../../../assets/icons/RecoverAssetValue.svg";
import { useNavigate } from 'react-router-dom';

export const ServiceSection = () => {
  const navigate = useNavigate()

  const goToServices = () => {
    navigate("/services")
  }
  
  return (
    <div className="w-full bg-[#121218] flex items-center justify-center">
      <div className="serviceSection container max-w-[1240px] py-5  px-4  md:py-9">
        <h1 className="m-auto w-fit">Our Service</h1>
        <div className="grid sm:grid-cols-2 lg:grid-cols-2 pt-[3rem] ">
          {/* property Management-card */}
          <div class="max-w-max   border border-[#ffff] rounded-[10px] shadow bg-[#121218] group m-4 mt-14">
            <div class="relative">
              <div className="absolute -translate-y-14 w-[100%]">
                <div className="w-fit m-auto border border-[#ffff] rounded-[50px] p-[5px] bg-[#121218]">
                  <div className="relative p-4 ">
                    <img
                      src={loadingRound}
                      alt="loading loop"
                      className="absolute left-0 top-0 hidden group-hover:block animate-spin"
                    />
                    <img src={propertyManagement} alt="service icon m-auto" />
                  </div>
                </div>
              </div>
              <div className="p-4 pt-[2.7rem]">
                <h5 class="mb-2 tracking-tight text-white m-auto w-fit">
                  Computer Dealership
                </h5>
                <div className="relative">
                  {/* <span className="w-full h-14 bg-gradient-to-t from-[#121218] to-transparent absolute left-0 bottom-0 "></span> */}
                  <p class="m-0 font-normal  text-gray-400 text-justify">
                    We offer an extensive selection of desktops, laptops,
                    servers, and peripherals from the industry’s most trusted
                    brands. Whether you're looking to upgrade your office
                    equipment or set up a new IT infrastructure, we provide
                    tailored solutions to meet your budget and performance
                    needs. Count on us for high-quality hardware that enhances
                    productivity and reliability in your business operations.
                  </p>
                </div>

                {/* <button
                  onClick={goToServices}
                  className="flex items-center gap-4 text-[#ffff] font-normal text-[0.9rem] mt-4 md:text-[1rem] m-auto"
                >
                  View More
                  <MdOutlineKeyboardDoubleArrowRight className="arrow-r-bounce text-[0.9rem] md:text-[1.3rem]" />
                </button> */}
              </div>
            </div>
          </div>
          {/* Capital Improvements-card */}
          <div class="max-w-max   border border-[#ffff] rounded-[10px] shadow bg-[#121218] group m-4 mt-14">
            <div class="relative">
              <div className="absolute -translate-y-14 w-[100%]">
                <div className="w-fit m-auto border border-[#ffff] rounded-[50px] p-[5px] bg-[#121218]">
                  <div className="relative p-4 ">
                    <img
                      src={loadingRound}
                      alt="loading loop"
                      className="absolute left-0 top-0 hidden group-hover:block animate-spin"
                    />
                    <img src={capitalImprovements} alt="service icon m-auto" />
                  </div>
                </div>
              </div>
              <div className="p-4 pt-[2.7rem]">
                <h5 class="mb-2 tracking-tight text-white m-auto w-fit">
                  Networking Solutions
                </h5>
                <div className="relative">
                  {/* <span className="w-full h-14 bg-gradient-to-t from-[#121218] to-transparent absolute left-0 bottom-0"></span> */}
                  <p class="m-0 font-normal text-gray-400 text-justify ">
                    From network design and installation to ongoing maintenance
                    and support, our networking solutions ensure that your
                    business stays connected. We specialize in creating robust,
                    secure, and scalable networks that enhance communication,
                    optimize performance, and reduce downtime. Whether you're
                    setting up a small office network or managing a complex
                    enterprise system, we've got the expertise to handle it all.
                  </p>
                </div>

                {/* <button
                  onClick={goToServices}
                  className="flex items-center gap-4 text-[#ffff] font-normal text-[0.9rem] mt-4 md:text-[1rem] m-auto"
                >
                  View More
                  <MdOutlineKeyboardDoubleArrowRight className="arrow-r-bounce text-[0.9rem] md:text-[1.3rem]" />
                </button> */}
              </div>
            </div>
          </div>
          {/* Finance Real Estate-card */}
          <div class="max-w-max   border border-[#ffff] rounded-[10px] shadow bg-[#121218] m-4 group mt-14">
            <div class="relative">
              <div className="absolute -translate-y-14 w-[100%]">
                <div className="w-fit m-auto border border-[#ffff] rounded-[50px] p-[5px] bg-[#121218]">
                  <div className="relative p-4 ">
                    <img
                      src={loadingRound}
                      alt="loading loop"
                      className="absolute left-0 top-0 hidden group-hover:block animate-spin"
                    />
                    <img src={financeRealEstate} alt="service icon m-auto" />
                  </div>
                </div>
              </div>
              <div className="p-4 pt-[2.7rem]">
                <h5 class="mb-2 tracking-tight text-white m-auto w-fit">
                  Cybersecurity
                </h5>
                <div className="relative">
                  {/* <span className="w-full h-14 bg-gradient-to-t from-[#121218] to-transparent absolute left-0 bottom-0"></span> */}
                  <p class="m-0 font-normal text-gray-400 text-justify ">
                    Safeguard your business from evolving cyber threats with our
                    comprehensive cybersecurity services. We offer firewall
                    management, data encryption, vulnerability assessments, and
                    real-time monitoring to ensure your digital assets are
                    protected. Let us help you implement proactive security
                    measures that keep your business secure and compliant with
                    industry standards.
                  </p>
                </div>

                {/* <button
                  onClick={goToServices}
                  className="flex items-center gap-4 text-[#ffff] font-normal text-[0.9rem] mt-4 md:text-[1rem] m-auto"
                >
                  View More
                  <MdOutlineKeyboardDoubleArrowRight className="arrow-r-bounce text-[0.9rem] md:text-[1.3rem]" />
                </button> */}
              </div>
            </div>
          </div>
          {/* Financial Reporting-card */}
          <div class="max-w-max   border border-[#ffff] rounded-[10px] shadow bg-[#121218] m-4 group mt-14">
            <div class="relative">
              <div className="absolute -translate-y-14 w-[100%]">
                <div className="w-fit m-auto border border-[#ffff] rounded-[50px] p-[5px] bg-[#121218]">
                  <div className="relative p-4 ">
                    <img
                      src={loadingRound}
                      alt="loading loop"
                      className="absolute left-0 top-0 hidden group-hover:block animate-spin"
                    />
                    <img src={financialReporting} alt="service icon m-auto" />
                  </div>
                </div>
              </div>
              <div className="p-4 pt-[2.7rem]">
                <h5 class="mb-2 tracking-tight text-white m-auto w-fit">
                  Cloud Computing
                </h5>
                <div className="relative">
                  {/* <span className="w-full h-14 bg-gradient-to-t from-[#121218] to-transparent absolute left-0 bottom-0"></span> */}
                  <p class="m-0 font-normal text-gray-400 text-justify ">
                    Embrace the power of the cloud with our cloud migration,
                    management, and optimization services. Whether you're
                    looking to move your operations to the cloud or optimize
                    your existing cloud infrastructure, we provide solutions
                    that boost scalability, enhance flexibility, and reduce IT
                    costs. Our team ensures seamless transitions and continuous
                    support to help you get the most out of your cloud
                    investment.
                  </p>
                </div>

                {/* <button
                  onClick={goToServices}
                  className="flex items-center gap-4 text-[#ffff] font-normal text-[0.9rem] mt-4 md:text-[1rem] m-auto"
                >
                  View More
                  <MdOutlineKeyboardDoubleArrowRight className="arrow-r-bounce text-[0.9rem] md:text-[1.3rem]" />
                </button> */}
              </div>
            </div>
          </div>
          {/* Recover Asset Value-card */}
        </div>
      </div>
    </div>
  );
}

export default ServiceSection