import React from 'react'
import aboutImg from '../../../../assets/home-page/section1.webp'
import { useNavigate } from 'react-router-dom';

  

export const Section1 = () => {
  const navigate = useNavigate();
  
  //navigation
  const goToAboutPage = () => {
    navigate("/about");
  };
  return (
    <div className="about-section w-full bg-[#040406] flex items-center justify-center">
      <div className="container max-w-[1240px] py-5  px-4  md:py-9 grid  md:grid-cols-2 relative">
        <div>
          <h1>Reliable IT Solutions</h1>
          <h3 className="load-text !capitalize">Security and Reliability</h3>
          <p className="text-justify pr-3">
            At Ignitex, we prioritize security and reliability. We implement
            robust security measures to protect your data and systems from
            threats. Our team is committed to providing reliable IT solutions
            that keep your business running smoothly.
          </p>
          <h3 className="load-text !capitalize">Proactive Maintenance</h3>
          <p className="text-justify pr-3">
            Our proactive maintenance services help prevent potential issues
            before they arise, ensuring optimal performance and minimizing
            downtime.
          </p>
          <h3 className="load-text !capitalize">Data Backup and Recovery</h3>
          <p className="text-justify pr-3">
            We offer comprehensive data backup and recovery solutions to
            safeguard your valuable information. Rest assured, your data is
            protected in case of unforeseen circumstances.
          </p>
        </div>

        <img
          src={aboutImg}
          alt="about image"
          className="w-[80%] order-last md:w-[80%] m-auto"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default Section1;