import React, { useState } from 'react'
import BannerContact from './component/BannerContact'
import ContactForm from './component/ContactForm';
import Assist from "../../../assets/icons/assist.svg";
import Consultation from "../../../assets/icons/consultation.svg";
import Estimation from "../../../assets/icons/estimation.svg";
import Shedule from "../../../assets/icons/shedule.svg";
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const Contact = () => {
   const [keywords, setKeywords] = useState([]);

   // Scroll to the top
   useEffect(() => {
     window.scrollTo({ top: 0, behavior: "smooth" });
     // Fetch keywords from an API or set them directly
     
   },[]);
  return (
    <div>
      <Helmet>
        <title>
          Contact Our IT Expert Team | Get in Touch technology Assistance
        </title>
        <meta name="keywords" content={keywords.join(", ")} />
        <meta
          name="description"
          content="Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs."
        />

        {/* -- Open Graph Meta Tags for WhatsApp and Social Media Sharing -- */}
        <meta
          property="og:title"
          content="Contact Our IT Expert Team | Get in Touch technology Assistance"
        />
        <meta
          property="og:description"
          content="Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs."
        />
        {/* <meta property="og:image" content="https://www.dnkre.com/logo.webp" /> */}
        {/* <meta property="og:url" content="https://dnkre.com/contact" />
        <meta property="og:type" content="https://dnkre.com/contact" /> */}

        {/* -- Twitter Card for Sharing -- */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contact Our IT Expert Team | Get in Touch technology Assistance"
        />
        <meta
          name="twitter:description"
          content="Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs."
        />
        <meta
          name="twitter:image"
          content="https://www.ignitextec.com/logo.webp"
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebSite",
            mainEntity: {
              "@type": "ContactPage",
              name: "Contact Our IT Expert Team | Get in Touch technology Assistance",
              description:
                "Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs.",
              keywords: keywords.join(", "),
            },
          })}
        </script>
      </Helmet>
      <BannerContact />
      <div className=" w-full bg-[#040406] flex items-center justify-center">
        <div className="container max-w-[1240px] py-5  px-4  md:py-9">
          <h1 className="m-auto w-[100%] sm:w-[90%] md:w-[70%] text-center">
            Contact IT Solution in Dubai.
          </h1>
          <p className="text-center m-auto w-[100%] md:w-[80%]">
            Looking for help or any support? We are available for you.
          </p>
        </div>
      </div>
      <div className=" w-full bg-[#040406] flex items-center justify-center">
        <div className="container max-w-[1240px] py-5  px-4  md:py-9">
          <div className="grid  md:grid-cols-2 relative">
            <div className="grid grid-cols-2">
              <div className="p-2">
                <img
                  src={Assist}
                  alt="info icon"
                  className="pb-4 m-auto md:m-0"
                />
                <h5 className="text-center md:text-left text-[#ffffff]">
                  Call Enquiry Assistance
                </h5>
                <p className="text-center md:text-left">
                  We are always available on call to assist you with any
                  questions you may have.
                </p>
              </div>
              <div className="p-2">
                <img
                  src={Consultation}
                  alt="info icon"
                  className="pb-4 m-auto md:m-0"
                />
                <h5 className="text-center md:text-left text-[#ffffff]">
                  Project Consultation
                </h5>
                <p className="text-center md:text-left">
                  We provide you with all technical consultations for your
                  project.
                </p>
              </div>
              <div className="p-2">
                <img
                  src={Estimation}
                  alt="info icon"
                  className="pb-4  m-auto md:m-0"
                />
                <h5 className="text-center md:text-left text-[#ffffff]">
                  Project Estimation
                </h5>
                <p className="mb-0 text-center md:text-left">
                  You come to us with your project, and we do all of the
                  planning and costing for you.
                </p>
              </div>
              <div className="p-2">
                <img
                  src={Shedule}
                  alt="info icon"
                  className="pb-4  m-auto md:m-0"
                />
                <h5 className="text-center md:text-left text-[#ffffff]">
                  Scheduling Appointments
                </h5>
                <p className="mb-0 text-center md:text-left">
                  We assist you in scheduling appointments whenever you wish to
                  meet us.
                </p>
              </div>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
      <iframe
        class="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.563951136491!2d55.282790899999995!3d25.2179236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f437da3f6745d%3A0xa9db0f0beef1d510!2sIGNITEX%20INFORMATION%20TECHNOLOGY%20LLC!5e0!3m2!1sen!2sae!4v1727958742423!5m2!1sen!2sae"
      ></iframe>
    </div>
  );
}

export default Contact