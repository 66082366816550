import React from 'react'
import aboutImg from '../../../../assets/home-page/about-home-img.png'
import { useNavigate } from 'react-router-dom';

  

export const AboutSectionMain = () => {
  const navigate = useNavigate();
  
  //navigation
  const goToAboutPage = () => {
    navigate("/about");
  };
  return (
    <div className="about-section w-full bg-[#040406] flex items-center justify-center">
      <div className="container max-w-[1240px] py-5  px-4  md:py-9 grid  md:grid-cols-2 relative">
        <img
          src={aboutImg}
          alt="about image"
          className="w-[80%] order-last md:order-first md:w-[80%] m-auto"
          loading="lazy"
        />
        <div>
          <div className="flex items-end justify-start mb-2">
            <h3 className="load-text">Who we are</h3>
            <span className="loadDot dot1"></span>
            <span className="loadDot dot2"></span>
            <span className="loadDot dot2"></span>
          </div>
          <h1>Select the Best IT Solution for Your Business Growth</h1>
          <p className="text-justify pr-3">
            Welcome to Ignitex Information Technology LLC, where we specialize
            in delivering innovative IT solutions tailored to fuel your business
            growth. Led by a team of seasoned professionals, we bring extensive
            experience and cutting-edge technology to the table. Our
            client-centric approach ensures that we deeply understand your
            unique challenges and goals, allowing us to craft solutions that are
            not only efficient but also scalable for future growth. Whether it's
            IT infrastructure, software development, or cybersecurity, we
            provide end-to-end services that align perfectly with your needs.
          </p>
          <p className="text-justify pr-3">
            At Ignitex, excellence and innovation drive everything we do. We are
            committed to continuous improvement and delivering results that
            exceed expectations. Our solutions empower businesses to streamline
            operations, enhance productivity, and achieve sustained success in a
            competitive digital landscape. Partner with us to transform your
            technology into a strategic asset that sets you apart from the
            competition.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutSectionMain;