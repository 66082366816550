import React, { useState } from 'react'
import AboutBanner from './components/AboutBanner'
import AboutDetail from './components/AboutDetail'
import TeamSection from '../home/components/TeamSection'
import OurProcess from '../home/components/OurProcess'
import ReviewSection from '../home/components/ReviewSection'
import PartnerSection from '../home/components/PartnerSection'
import TalkSection from '../home/components/TalkSection'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AboutSection from '../home/components/AboutSection'
import AboutSectionMain from '../home/components/AboutSectionMain'

export const About = () => {
   const [keywords, setKeywords] = useState([]);

  // Scroll to the top
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Fetch keywords from an API or set them directly
  },[]);
  return (
    <div>
      <Helmet>
        <title>About Us</title>
        <meta name="keywords" content={keywords.join(", ")} />
        

        <meta
          property="og:title"
          content="About Us"
        />
      </Helmet>
      <AboutBanner />
      <AboutSectionMain />
      <PartnerSection />
      <TalkSection />
    </div>
  );
}

export default About