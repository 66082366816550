import axiosPrivate from "../url/axios";

export const useProjectServices = () => {

    const postProjectList = async (formdata) => {
       
    }
    const putProjectList = async (id, data) => {
       
       
    }
    const getProjectList = async () => {
       
    }
    const getProjectPublicList = async () => {
       
    }
    const getProjectById = async (projectname) => {
       
    }
    const deleteProjectList = async (id, data) => {
        
    }
    const postAdImage = async (formdata) => {
     
    }
    const getAd = async () => {
      
    }
    const putAd = async (id, data) => {
       
        
    }

    
    return {
        postProjectList,
        putProjectList,
        getProjectList,
        getProjectPublicList,
        deleteProjectList,
        getProjectById,
        postAdImage,
        getAd,
        putAd
    }
}