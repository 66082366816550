import axiosPrivate from "../url/axios";

export const userPartnerServices = () => {
    const postPartner = async (formdata) => {
       
    }

    const getPartner = async () => {
        
    };

    const getPartnerName = async (partnerName) => {
       
    };

    const putPartner = async (id, data) => {
       
    }

    const deletePartner = async (id) => {
       
    }

    return {
        postPartner,
        getPartner,
        putPartner,
        deletePartner,
        getPartnerName
    }

}