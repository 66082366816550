import React, { useEffect, useState } from 'react'
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import propertyManagement from "../../../assets/icons/propertyManagement.svg";
import loadingRound from "../../../assets/icons/loadingRound.svg";
import capitalImprovements from "../../../assets/icons/capitalImprovement.svg";
import financeRealEstate from "../../../assets/icons/FinanceRealEstate.svg";
import financialReporting from "../../../assets/icons/FinancialReporting.svg";
import recoverAssetValue from "../../../assets/icons/RecoverAssetValue.svg";
import TalkSection from '../home/components/TalkSection';
import PartnerSection from '../home/components/PartnerSection';
import ReviewSection from '../home/components/ReviewSection';
import OurProcess from '../home/components/OurProcess';
import BannerService from './component/BannerService';
import { Helmet } from 'react-helmet';
import Section1 from '../home/components/Section1';
import Section2 from '../home/components/Section2';
import ServiceSection from '../home/components/ServiceSection';

export const OurServices = () => {
    const [keywords, setKeywords] = useState([]);

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });

     
    }, []);

    return (
      <div>
        <Helmet>
          <title>Our Services in Dubai</title>
        </Helmet>
        <BannerService />
        <ServiceSection />
        <Section1 />
        <Section2 />
        <PartnerSection />
        <TalkSection />
      </div>
    );
}

export default OurServices