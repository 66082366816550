import React from 'react'
import aboutImg from '../../../../assets/home-page/section2.webp'
import { useNavigate } from 'react-router-dom';

  

export const Section2 = () => {
  const navigate = useNavigate();
  
  //navigation
  const goToAboutPage = () => {
    navigate("/about");
  };
  return (
    <div className="about-section w-full bg-[#040406] flex items-center justify-center">
      <div className="container max-w-[1240px] py-5  px-4  md:py-9 grid  md:grid-cols-2 relative">
        <div>
          <h1>Tailored to Your Needs</h1>
          <h3 className="load-text !capitalize">Needs Assessment</h3>
          <p className="text-justify pr-3">
            We begin with a thorough assessment of your business needs and IT
            infrastructure.
          </p>
          <h3 className="load-text !capitalize">Solution Design</h3>
          <p className="text-justify pr-3">
            We design a customized IT solution that addresses your specific
            requirements.
          </p>
          <h3 className="load-text !capitalize">Implementation & Training</h3>
          <p className="text-justify pr-3">
            Our team implements the solution and provides comprehensive training
            to your staff.
          </p>
          <h3 className="load-text !capitalize">Ongoing Support</h3>
          <p className="text-justify pr-3">
            We provide ongoing support and maintenance to ensure the smooth
            operation of your IT systems.
          </p>
        </div>

        <img
          src={aboutImg}
          alt="about image"
          className="w-[80%] order-last md:order-first md:w-[80%] m-auto"
          loading="lazy"
        />
      </div>
    </div>
  );
}

export default Section2;